
import { Component, Vue, } from 'vue-property-decorator';

@Component<PriceList>({})
export default class PriceList extends Vue {

  // protected mounted(): void {
  
  // }

protected Trainingen = [ 
  { title: "Overtuigend presenteren", image: require("../../assets/images/trainingen/leiderschap.jpg"), text: "Wil je je publiek boeien? Je stem beter gebruiken? Pakkender spreken met meer impact? Presenteren met meer flair? In deze training ga je daar mee aan de slag. Stapsgewijs verbeteren we  samen de non-verbale presentatie, de voorbereiding, de betoogvorm en woordkeuze."},
  { title: "Overtuigend debatteren", image: require("../../assets/images/trainingen/banner2.jpg"), text: "Leer systematisch argumenteren, adequaat reageren en improviseren, framing herkennen en reframing toepassen. Herken en weerleg drogredenen en oefen debattechnieken toegespitst op jouw werksituatie. Er wordt veel gelachen en toch serieus geoefend en al snel heb ook jij de kunst van het debatteren onder de knie."},
  { title: "Overtuigend schrijven", image: require("../../assets/images/trainingen/tafel.jpg"), text: "Wil je stukken schrijven die meer impact hebben en beter leesbaar zijn? Zaken pakkender verwoorden en sneller tot de kern komen?  In deze training ga je met je eigen teksten aan de slag. Stapsgewijs verbeteren we  samen tekst, de betooglijn en de woordkeuze."},
  { title: "Overtuigend voorzitten en effectief vergaderen", image: require("../../assets/images/trainingen/laptop.jpg"), text: "Duren vergaderingen te lang en zijn ze erg saai? Zijn de deelnemers erg passief? Wil je weten hoe je als voorzitter of als deelnemer constructief en effectief kunt vergaderen? Dan is deze training perfect. We verbeteren de opzet en tekst van de vergaderagenda, stroomlijnen het vergaderproces en oefenen met verschillende werkvormen."},
  { title: "Positief beïnvloeden met de inzichten van Cialdini", image: require("../../assets/images/trainingen/banner.jpg"), text: "Onze hersens zijn lui. In plaats van nadenken over argumenten zijn onze hersens gevoelig voor een aantal beïnvloedingsprincipes. Robert Cialdini schreef in zijn baandoorbrekende boek ‘Influence’ over zijn jarenlange onderzoek. Oefen met deze principes en vergroot je overtuigingskracht door niet alleen te argumenteren maar ook deze psychologische inzichten te gebruiken om mensen positief te beïnvloeden."},
  { title: "Overtuigend onderhandelen", image: require("../../assets/images/trainingen/handen.jpg"), text: "Onderhandelen is een kunst die je kunt leren. Door je goed voor te bereiden en veel te oefenen maak ook jij je deze kunst eigen. Ontdek zelf hoe leuk en nuttig onderhandelen kan zijn. Leer de stappen bij een goede voorbereiding en de valkuilen waar je voor moet uitkijken."},
];
}
