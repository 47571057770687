<template>
  <div
    class="flex flex-wrap items-center containerFull relative w-full bannerContainer z-10"
  >
    <img
      v-if="blackWhite"
      class="imgBlackWhite absolute h-full w-full object-cover z-0"
      :src="require(`../../assets/images/features/${imageLink}`)"
      alt="Banner background image"
    />
    <img
      v-else
      class="absolute h-full w-full object-cover z-0"
      :src="require(`../../assets/images/features/${imageLink}`)"
      alt="Banner background image"
    />
    <div class="container text-center; relative z-20">
      <div class="px-32">
        <h2 class="bannerTitle text-center ">{{ text }}</h2>
         <router-link :to="buttonLink">
          <!-- todo: link nog fixen -->
        <div v-if="button" class="ctaButton text-center mt-16 text-2xl"> {{ buttonText }}</div>
      </router-link>
      </div>
        
    </div>
  

    <div
    v-if="overlay"
    class="overlay absolute top-0 bottom-0 right-0 left-0 object-cover w-full h-full"
  ></div>
  </div>
</template>

<style
  scoped
  lang="scss"
  src="@/components/FullWidthImage/full-width-image.scss"
></style>

<script>
export default {
  props: ["imageLink", "overlay", "blackWhite", "text", "button", "buttonText", "buttonLink"]
};
</script>
