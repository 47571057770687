








import { Component, Vue } from "vue-property-decorator";
import FullWidthImage from "@/components/FullWidthImage/FullWidthImage.vue";
import Recenties from "@/components/Recenties/Recenties.vue";
import ContactGegevens from "@/components/ContactGegevens/ContactGegevens.vue";

@Component({
  components: {
    FullWidthImage,
    Recenties,
    ContactGegevens,
  }
})
export default class Home extends Vue {
    public imagelink1 = "contact.jpg";
}
