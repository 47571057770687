












import { Component, Vue } from "vue-property-decorator";
import FullWidthImage from "@/components/FullWidthImage/FullWidthImage.vue";
import Intro from "@/components/Intro/Intro.vue";
import Trainingen from "@/components/Trainingen/Trainingen.vue";
import Persoonlijk from "@/components/Persoonlijk/Persoonlijk.vue";
import YoutubeIframe from "@/components/YoutubeIframe/YoutubeIframe.vue";
import Recenties from "@/components/Recenties/Recenties.vue";

@Component({
  components: {
    FullWidthImage,
    Intro,
    Trainingen,
    Persoonlijk,
    YoutubeIframe,
    Recenties,
  }
})
export default class Home extends Vue {
    public imagelink1 = "banner.jpg";
}
